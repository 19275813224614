import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'

import allRoutes from './configs/routers/appNavigation/allRoutes.json'
import navigationMap from './configs/routers/appNavigation/navigationMap';

function App() {
  return (
    <>
      <Router>
        <Routes>
          {
            Object.values(allRoutes).map(route => {
              return (
                <Route
                  key={route}
                  path={route}
                  element={
                    navigationMap[route]
                  }
                />
              )
            })
          }
          <Route
            path='*'
            element={(
              <Navigate
                to='/play'
                replace
              />
            )}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
