import collectionOptions from "../config/collectionOptions"
import sortOptions from "../config/sortOptions"

export const changeCurrentCollection = (
    collectionList: typeof collectionOptions,
    currentCollection: typeof collectionOptions[0],
) => {
    try {
        const currencyIndex = collectionList.findIndex(elem => {
            if (elem === currentCollection) {
                return true
            }
            return false
        })

        if (currencyIndex === -1) {
            throw new Error('collection name is not provided')
        }

        if (currencyIndex <= collectionList.length) {
            const temp = collectionList[currencyIndex]
            collectionList[currencyIndex] = collectionList[0]
            collectionList[0] = temp
        }
    } catch (error: any) {
        console.error(error.message)
    }
}

export const changeCurrentSortBy = (
    sortList: typeof sortOptions,
    currentSort: typeof sortOptions[0],
) => {
    try {
        const currencyIndex = sortList.findIndex(elem => {
            if (elem === currentSort) {

                return true
            }
            return false
        })

        if (currencyIndex === -1) {
            throw new Error('collection name is not provided')
        }

        if (currencyIndex <= sortList.length) {
            const temp = sortList[currencyIndex]
            sortList[currencyIndex] = sortList[0]
            sortList[0] = temp
        }
    } catch (error: any) {
        console.error(error.message)
    }
}