import React, { useEffect, useRef, useState } from 'react';

import CollectionDropdown from './CollectionDropdown';
import SortDropdown from './SortDropdown';
import CheckboxItem from './CheckboxItem';

import rarityItems from './config/rarityItems';
import classItems from './config/classItems';

import filterIcon from '../../../../img/tune_FILL0_wght400_GRAD0_opsz20.svg'
import close from '../../../../img/close.svg'


import styles from './styles/filter.css'

const Filter = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={styles.heroesFilter}>
            <div className={styles.filterButton}
                onClick={
                    () => {
                        setIsOpen(!isOpen)
                    }
                }>
                <img src={filterIcon} alt='' />
                <p>Filter</p>
            </div>
            {
                isOpen
                && (
                    <div className={styles.filterContent}>
                        <div className={styles.close}
                            onClick={() => {
                                setIsOpen(false)
                            }}
                        >
                            <img src={close} alt='close' />
                        </div>
                        <div className="collection">
                            <p className={styles.title}>Collection</p>
                            <CollectionDropdown />
                        </div>
                        <div className="sort_by">
                            <p className={styles.title}>Sort by</p>
                            <SortDropdown />
                        </div>
                        <div className="rarity">
                            <p className={styles.title}>Rarity</p>
                            <div className={styles.chFilter}>
                                {
                                    rarityItems.map(item => (
                                        <CheckboxItem
                                            content={item.content}
                                            icon={item.icon}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                        <div className="rarity">
                            <p className={styles.title}>Class</p>
                            <div className={styles.chFilter}>
                                {
                                    classItems.map(item => (
                                        <CheckboxItem
                                            content={item.content}
                                            icon={item.icon}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div >
                )
            }
        </div >
    );
};

export default Filter;