import React from 'react';
import styles from './styles/styles.css'

const NoNft = () => {
    return (
        <div className={styles.noNft}>
            <span className={styles.noNftText}>No NFTs to exchange</span>
        </div>
    );
};

export default NoNft;