import React from 'react';

import winStreak from '../../../img/stat_win_streak.svg'
import victory from '../../../img/stat_victory.svg'
import battles from '../../../img/stat_battles.svg'

import styles from '../styles/collections.css'

const Statistics = () => {
    return (
        <section className={styles.statSection}>
            <div className={styles.container}>
                <h2>MY STATISTICS</h2>
                <div className={styles.statRow}>
                    <div className={styles.stat}>
                        <img src={winStreak} alt='' />
                        <p className={styles.signific}>0</p>
                        <p className={styles.desc}>WINNING STREAK</p>
                    </div>
                    <div className={styles.stat}>
                        <img src={victory} alt='' />
                        <p className={styles.signific}>0%</p>
                        <p className={styles.desc}>VICTORIES</p>
                    </div>
                    <div className={styles.stat}>
                        <img src={battles} alt='' />
                        <p className={styles.signific}>0</p>
                        <p className={styles.desc}>BATTLES</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Statistics;