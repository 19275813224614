import React, {useEffect, useRef, useState} from 'react';

import collectionOptions from './config/collectionOptions';
import {changeCurrentCollection} from './helper/helper';

import styles from './styles/filter.css'
import {Collections} from "./enum/collections";

const CollectionDropdown = () => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const hideDropDownContent = (e: MouseEvent) => {
        if (!isOpen) {
            return
        }
        if (e.target instanceof Element && dropdownRef.current?.contains(e.target)) {
            return
        }

        setIsOpen(false)
    }

    useEffect(() => {
        document.addEventListener('click', hideDropDownContent)

        return () => {
            document.removeEventListener('click', hideDropDownContent)
        }
    }, [isOpen])

    const collection = useRef<HTMLLIElement | null>(null);

    return (
        <div className={styles.dropDown} ref={dropdownRef}>
            <div
                className={styles.selectItem}
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <span>{collectionOptions[0]}</span>
            </div>

            {
                isOpen
                &&
                (
                    <div className={styles.selectedItems} aria-labelledby="dropdownCollection">
                        <ul>
                            {
                                collectionOptions.map(option => (

                                    <li
                                        onClick={() => {
                                            changeCurrentCollection(collectionOptions, option)
                                            setIsOpen(false)
                                        }}
                                    >{option}</li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }
        </div>
    );
};

export default CollectionDropdown;