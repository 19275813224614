// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IC5c8 {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 1000;\n    background-color: rgb(255, 255, 255, 0.1);\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n}\n\n.Qqhzt {\n    animation: WiY_D .7s ease forwards;\n    background-color: transparent;\n}\n\n@keyframes WiY_D {\n    0% {\n        transform: translateY(-100px);\n        opacity: 0;\n    }\n\n    50% {\n        opacity: 1;\n    }\n\n    100% {\n        transform: translateY(0);\n    }\n}", "",{"version":3,"sources":["webpack://./src/screens/collections/components/CardModal/styles/heroCardModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,aAAa;IACb,yCAAyC;;IAEzC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,kCAAsC;IACtC,6BAA6B;AACjC;;AAEA;IACI;QACI,6BAA6B;QAC7B,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 1000;\n    background-color: rgb(255, 255, 255, 0.1);\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n}\n\n.modal {\n    animation: appearing .7s ease forwards;\n    background-color: transparent;\n}\n\n@keyframes appearing {\n    0% {\n        transform: translateY(-100px);\n        opacity: 0;\n    }\n\n    50% {\n        opacity: 1;\n    }\n\n    100% {\n        transform: translateY(0);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": "IC5c8",
	"modal": "Qqhzt",
	"appearing": "WiY_D"
};
export default ___CSS_LOADER_EXPORT___;
