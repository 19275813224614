import React, {useEffect, useState} from 'react';


import INft from '../../interfaces/nfts';

import victoryStatIcon from '../../img/stat_victory.svg'

import styles from './styles/styles.css'
import {getClassPic, getRarityPic} from "../collections/components/HeroCard/helper/helper";
import {buyNft, delistNft, fromYocto, getListings, getAccountId, listNft} from "../../api/near";
import stylesSlider from '../collections/components/Slider/styles/slider.css'
import Classes from "./enum/classes";
import {getCardClassName, getTraitPic} from "./helper/helper";


interface IProp {
    nft: INft,
}

const SellNftItem = (
    { nft }: IProp
) => {
    console.log(nft)
    const [amount, setAmount] = useState('');
    return (
        <div className={styles.heroCardWrapper}>
            <div className={getCardClassName(nft.attributes[0].value, nft.attributes[5].value)}>
                <div className={styles.heroLink}></div>
                <div className={styles.cardTop}>
                    <div className={styles.crown}>
                        <img src={victoryStatIcon} alt='' />
                        <p>42%</p>
                    </div>
                    <img src={nft.image} alt='' className={styles.heroImg} />
                    <p className={styles.nomination}>{nft.name}</p>
                </div>
                <div className={styles.cardBot}>
                    <div className={styles.rarityIcon}>
                        <div className={styles.iconWr}>
                            <div className={styles.iconIn}>
                                <img src={getTraitPic(nft.attributes[5].value)} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className={styles.rarity}>{nft.attributes[5].value}</div>
                    <div className={styles.className}>{nft.attributes[0].value}</div>
                    <div className={styles.classIcon}>
                        <div className={styles.iconWr}>
                            <div className={styles.iconIn}>
                                <img src={getTraitPic(nft.attributes[0].value)} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.heroCardSell}>
                <div className={stylesSlider.buyContent}>
                    <div className={stylesSlider.priceWrapper}>
                        <input
                            placeholder="Price NEAR"
                            type="number"
                            value={nft.sale_conditions ? fromYocto(nft.sale_conditions) : amount}
                            readOnly={Boolean(nft.sale_conditions)}
                            onChange={e => setAmount(e.target.value)}
                        />
                    </div>
                    <div
                        className={
                            nft.sale_conditions
                                ? nft.owner === getAccountId()
                                    ?  styles.cancelButton : styles.buyButton
                                : styles.cancelButton
                        }
                        onClick={async () => {
                            if (nft.sale_conditions && nft.owner === getAccountId()) {
                                return await delistNft(`${nft.edition}`)
                            }
                            if (nft.sale_conditions) {
                                return await buyNft(`${nft.edition}`, nft.sale_conditions)
                            }
                            await listNft(`${nft.edition}`, amount)
                        }}
                    >
                            <span>
                                {
                                    nft.sale_conditions
                                        ? nft.owner === getAccountId()
                                            ? 'CANCEL' : 'BUY'
                                        : 'SELL'
                                }
                            </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellNftItem;