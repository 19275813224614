import { useEffect, useState } from 'react'
import styles from './styles/styles.css'
import marketplaceArt from '../../img/marketplace_art@2x.png'
import { fromYocto, getSaleInfo, mint } from '../../api/near'

const Mint = () => {
    const [state, setState] = useState({
        maxAmount: 0,
        currentAmount: 0,
        price: 0,
        maxPerWallet: 0
    })

    const getInfo = async () => {
        const res = await getSaleInfo()
        if (res) setState({
            maxAmount: res[1],
            currentAmount: res[2],
            price: res[3],
            maxPerWallet: res[5]
        })
    }

    useEffect(() => {
        getInfo()
    }, [])

    return (
        <section className={styles.buyOpenSection}>
            <div className={styles.containerMint}>
                <p className={styles.title}>EASY BUY &amp; EASY SELL!</p>
                <p className={styles.description}>Exchange NFTs on our marketplace or partner sites on the official collection pages. <br />Have a great shopping experience!</p>
                <img src={marketplaceArt} alt='' />
                <div className={styles.mintContainer}>
                    <h3 className={styles.mintLeftAmount}>minted: {state.currentAmount}/{state.maxAmount}</h3>
                    <div
                        className={styles.mintButton}
                        onClick={() => mint()}
                    >
                        {/* <span>{fromYocto(state.price)} NEAR</span> */}
                        <span>MINT</span>
                    </div>
                    <span className={styles.mintWarning}>Max {state.maxPerWallet} mints per wallet</span>
                </div>
            </div>
        </section>
    )
}

export default Mint