import classNames from "classnames"

import {ClassesMarket, Classes} from "../enum/classes"
import Rarity from "../enum/rarity"

import assassinIcon from "../../../img/class_assassin.svg"
import fighterIcon from "../../../img/class_fighter.svg"
import rangerIcon from "../../../img/class_ranger.svg"
import commonIcon from "../../../img/rarity_common.svg"
import rareIcon from "../../../img/rarity_rare.svg"
import legendaryIcon from "../../../img/rarity_legendary.svg"

import styles from "../styles/styles.css"

export const getTraitPic = (value: string) => {
    if (value === ClassesMarket.assassin) return assassinIcon
    if (value === ClassesMarket.warrior) return fighterIcon
    if (value === ClassesMarket.mage) return rangerIcon
    if (value === Rarity.common) return commonIcon
    if (value === Rarity.rare) return rareIcon
    if (value === Rarity.legendary) return legendaryIcon

    return undefined
}

export const getClassNames = (value: string) => {
    if (value === Rarity.common) return classNames(styles.herocard, styles.common)
    if (value === Rarity.rare) return classNames(styles.herocard, styles.rare)
    if (value === Rarity.legendary) return classNames(styles.herocard, styles.legendary)

    return classNames(styles.herocard)
}

export const getCardClassName = (
    classType: string,
    rarity: string,
) => {
    const result = {
        rarityStyles: '',
        classTypeStyles: ''
    }

    if (classType === ClassesMarket.assassin) result.classTypeStyles = styles.assasin
    if (classType === ClassesMarket.warrior) result.classTypeStyles = styles.fighter
    if (classType === ClassesMarket.mage) result.classTypeStyles = styles.ranger

    if (rarity === Rarity.common) result.rarityStyles = styles.common
    if (rarity === Rarity.rare) result.rarityStyles = styles.rare
    if (rarity === Rarity.legendary) result.rarityStyles = styles.legendary


    return classNames(styles.heroCard, result.rarityStyles, result.classTypeStyles)
}
