import rarityCommon from '../../../../../img/rarity_common.svg'
import rarityLegendary from '../../../../../img/rarity_legendary.svg'
import rarityRare from '../../../../../img/rarity_rare.svg'

const rarityItems = [
    {
        icon: rarityCommon,
        content: 'Common',
    },
    {
        icon: rarityRare,
        content: 'Rare',
    },
    {
        icon: rarityLegendary,
        content: 'Legendary',
    },
]

export default rarityItems