import React, {useEffect, useRef} from 'react';
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import {useUnityContext, Unity} from "react-unity-webgl";
import {
    approveActions,
    getAccountId,
    getMyLastBattle,
    getOpenedBattles,
    getPlayerNfts,
    getPlayersForUnity,
    isSignIn,
    joinBattle,
    removeLastBatttle,
    requestSignIn,
    SendActions,
    UseBoost
} from '../../api/near';

let send = false;
const Play = () => {
    const webglRef = useRef<HTMLCanvasElement>(null)

    const {
        unityProvider,
        sendMessage,
        addEventListener,
        removeEventListener,
        isLoaded,
        loadingProgression,
    } = useUnityContext({
        loaderUrl: "build/build23.loader.js",
        dataUrl: "build/build23.data",
        frameworkUrl: "build/build23.framework.js",
        codeUrl: "build/build23.wasm",
    });

    async function CallLogin() {
        if (!isSignIn()) {
            await requestSignIn();
        }
    }

    async function CallGetAcc() {
        var account = getAccountId();
        // //console.log(account);
        
        sendMessage('JSWrapper', 'SavePlayerID', account);
        await CallGetOpenedGames();
    }

    async function GetAllOpenedGames() {
        await getOpenedBattles();
    }

    async function CallApproveAction(data: any) {
        await approveActions();
    }

    const sendCotleta = (info: any) => {
        // //console.log('send cotleta')
        console.log(JSON.stringify(info))
        sendMessage('JSWrapper', 'ReceiveKotleta', JSON.stringify(info))
    }

    async function CallGetOpenedGames() {
        const games = await getMyLastBattle(sendCotleta);
        // //console.log(games);
        if (games != null) {
            CallJoinBattleCallback(games);
        }
    }

    async function CallSendActions(actions: any) {
        const info = JSON.parse(actions);
        // //console.log(info);
        let data = [];
        data.push(Number(info.action1));
        data.push(Number(info.action2));
        data.push(Number(info.action3));
        // //console.log(data);
        await SendActions(data);
    }


    async function CallJoinBattle(NFTId: any, bet: any, gameId: any) {
        // //console.log(NFTId);
        await joinBattle(NFTId);
    }

    async function CallJoinBattleCallback(data: any) {
        // //console.log('CallJoinBattleCallback')
        // //console.log(JSON.stringify(data))
        if (!send) {
            send = true;
            sendMessage('JSWrapper', 'JoinGameCallBack', JSON.stringify(data))
        }
    }


    async function CallUseBoost(boostId: any) {
        const boostIndex = Number(boostId);
        //console.log(boostIndex + " boostIndex");
        await UseBoost(boostIndex);
    }


    //Вызов каждый раз nearwallet
    // useEffect(() => {
    //      CallLogin()
    // }, [])

    useEffect(() => {
        if (isLoaded) {
            let account = getAccountId();
            console.log(Boolean(account));
            if(!account) {
                account = "unknown";
            }
            console.log(account);
            
            sendMessage('JSWrapper', 'SavePlayerID', account)
            // getPlayersForUnity().then((res) => {
            //     console.log(res);
            //     sendMessage('JSWrapper', 'SetPlayerNFTS', JSON.stringify(res))
            // })
        }
    }, [isLoaded])

    useEffect(() => {

        // requestFullscreen(true)
        addEventListener('CallGetPlayersNfts', async () => {
            //console.log('CallGetPlayersNfts')
            let account = getAccountId();
            console.log(account);
            
            if(!account) {
                
            sendMessage('JSWrapper', 'SavePlayerID', account)
                return
            }
            console.log(account);
            const res = await getPlayersForUnity()
            console.log(JSON.stringify(res));

            //console.log(res)
            sendMessage('JSWrapper', 'SetPlayerNFTS', JSON.stringify(res))
        })

        addEventListener('CallLogin', async () => {
            //console.log('CallLogin')
            await CallLogin()
        })

        addEventListener('CallGetAcc', async () => {
            //console.log('CallGetAcc')
            await CallGetAcc()
        })

        addEventListener('GetAllOpenedGames', async () => {
            //console.log('GetAllOpenedGames')
            await GetAllOpenedGames()
        })

        addEventListener('CallApproveAction', async (data: any) => {
            //console.log('CallApproveAction')
            await CallApproveAction(data)
        })

        addEventListener('CallGetOpenedGames', async () => {
            //console.log('CallGetOpenedGames')
            await CallGetOpenedGames()
        })

        addEventListener('CallSendActions', async (actions: any) => {
            //console.log('CallSendActions')
            await CallSendActions(actions)
        })

        addEventListener('CallJoinBattle', async (NFTId: any, bet: any, gameId: any) => {
            //console.log('CallJoinBattle')
            await CallJoinBattle(NFTId, bet, gameId)
        })

        addEventListener('CallUseBoost', async (boostId: any) => {
            //console.log('CallUseBoost')
            await CallUseBoost(boostId)
        })

        return () => {
            removeEventListener('CallGetPlayersNfts', () => {
            })
            removeEventListener('CallLogin', () => {
            })
            removeEventListener('CallGetAcc', () => {
            })
            removeEventListener('GetAllOpenedGames', () => {
            })
            removeEventListener('CallApproveAction', () => {
            })
            removeEventListener('CallGetOpenedGames', () => {
            })
            removeEventListener('CallSendActions', () => {
            })
            removeEventListener('CallJoinBattle', () => {
            })
            removeEventListener('CallUseBoost', () => {
            })
        }
    }, [removeEventListener, addEventListener, isLoaded])

    useEffect(() => {
        document.addEventListener('wheel', onScroll, false);
        document.addEventListener('mousemove', onMouse, false);

        function onScroll() {
            if (webglRef.current) webglRef.current.style.pointerEvents = 'none';
        }

        function onMouse() {
            if (webglRef.current) webglRef.current.style.pointerEvents = 'auto';
        }

        return () => {
            document.removeEventListener('wheel', onScroll, false);
            document.removeEventListener('mousemove', onMouse, false);
        }
    }, [])

    return (
        <div>
            <Header/>
            <div style={{position: 'relative', background:isLoaded ? "transparent" : "#231f20"}}>
                {!isLoaded &&
                    (<div style={{position:"relative", width:'100vw', height:'100vh', background:'#231f20'}}>
                        <p style={{position:'absolute', color: '#FFFFFF',  left:'45%', top:"50%", fontSize: '24px'}}>
                            Loading Game... {Math.round(loadingProgression * 100)}%
                        </p>
                    </div>)}
                <Unity
                    ref={webglRef}
                    unityProvider={unityProvider}
                    style={{
                        height: 'auto',
                        width: '100%',
                        display: "block",
                        visibility: isLoaded ? "visible" : "hidden"
                    }}
                />
            </div>
            <Footer/>
        </div>
    );
};

export default Play;