import classAssassin from '../../../../../img/class_assassin.svg'
import classFighter from '../../../../../img/class_fighter.svg'
import classRanger from '../../../../../img/class_ranger.svg'

const classItems = [
    {
        icon: classFighter,
        content: 'Fighter',
    },
    {
        icon: classRanger,
        content: 'Ranger',
    },
    {
        icon: classAssassin,
        content: 'Assassin',
    },
]

export default classItems