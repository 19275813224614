import React, {useState} from 'react';
import {getTraitPic, getClassNames} from './helper/helper'

import heroIcon from '../../img/coll01_ch001.png'
import moreIcon from '../../img/more_vert.svg'

import styles from './styles/styles.css'

import INft from '../../interfaces/nfts'
import {useAppDispatch} from '../../redux/hooks';
import {dispatchSelectedHeroId, dispatchHeroModalIsActive} from '../../redux/slices/heroModal';
import {buyNft, delistNft, fromYocto, getAccountId} from '../../api/near';
import {getClassPic} from "../collections/components/Slider/helper/helper";

interface IProp {
    card: INft,
}

const HeroCard = ({
                      card,
                  }: IProp) => {
    const dispatch = useAppDispatch()

    const changeColorBtn = () => {
        if(card.owner === getAccountId()) {
            return styles.herocardBuyBtnRed
        } else {
            return styles.herocardBuyBtn
        }
    }

    return (
        <div className={styles.herocardContainer}>
            <div className={getClassNames(card.attributes[5].value)}>
                <div className={styles.herocardBody} onClick={() => {
                    dispatch(dispatchSelectedHeroId(card.name))
                    dispatch(dispatchHeroModalIsActive(true))
                }}>
                    <div className={styles.imgContainer}>
                        <img className={styles.heroImg} src={card.image} alt=''/>
                        <h3 className={styles.heroTitle}>{card.name}</h3>
                        {/*<div onClick={() => console.log(123)} className={styles.moreWrapper}>*/}
                        {/*    <img src={moreIcon} alt=''/>*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.attributesContainer}>
                        <h2 className={styles.attributesTitle}>Attributes</h2>
                        <div className={styles.attributesStat}>
                            {
                                card.attributes.map(trait => (
                                    <div className={styles.statItem}>
                                        <span className={styles.traitType}>{trait.trait_type}</span>
                                        <div className={styles.traitNameContainer}>
                                            <img src={getClassPic(trait.value)} alt=''/>
                                            <span className={styles.traitName}>{trait.value}</span>
                                        </div>
                                        <span className={styles.traitPersent}>33.33%</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.herocardFooter}>
                    <span>Current price:</span>
                    <div className={styles.herocardPriceContainer}>
                        <span className={styles.herocardPrice}>{fromYocto(card.sale_conditions || '0')} NEAR</span>
                        <div
                            className={changeColorBtn()}
                            onClick={async () => {
                                if (card.owner === getAccountId()) {
                                    await delistNft(`${card.edition}`)
                                } else {
                                    await buyNft(`${card.edition}`, card.sale_conditions!)
                                }
                            }}
                        >
                            <span>{card.owner === getAccountId() ? 'CANCEL' : 'BUY'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroCard;