import classNames from 'classnames'

import commonIcon from '../../../../../img/rarity_common.svg'
import rareIcon from '../../../../../img/rarity_rare.svg'
import legendaryIcon from '../../../../../img/rarity_legendary.svg'
import assassinIcon from '../../../../../img/class_assassin.svg'
import fighterIcon from '../../../../../img/class_fighter.svg'
import rangerIcon from '../../../../../img/class_ranger.svg'

import Rarity from '../enum/rarity'
import Classes from '../enum/classes'

import styles from '../../../styles/collections.css'

export const getRarityPic = (rarity: string) => {
    if (rarity === Rarity.common) return commonIcon
    if (rarity === Rarity.rare) return rareIcon
    if (rarity === Rarity.legendary) return legendaryIcon

    return undefined
}

export const getClassPic = (classType: string) => {
    if (classType === Classes.assasin) return assassinIcon
    if (classType === Classes.warrior) return fighterIcon
    if (classType === Classes.mage) return rangerIcon

    return undefined
}

export const getCardClassName = (
    classType: string,
    rarity: string,
    isAble: boolean
) => {
    const result = {
        rarityStyles: '',
        classTypeStyles: ''
    }

    if (classType === Classes.assasin) result.classTypeStyles = styles.assasin
    if (classType === Classes.warrior) result.classTypeStyles = styles.fighter
    if (classType === Classes.mage) result.classTypeStyles = styles.ranger

    if (rarity === Rarity.common) result.rarityStyles = styles.common
    if (rarity === Rarity.rare) result.rarityStyles = styles.rare
    if (rarity === Rarity.legendary) result.rarityStyles = styles.legendary


    if (isAble) {
        return classNames(styles.heroCard, result.rarityStyles, result.classTypeStyles)
    }
    return classNames(styles.heroCard, result.rarityStyles, result.classTypeStyles, styles.disabled)
}
