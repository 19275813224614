import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHeroModalState } from "./interface";

const initialState: IHeroModalState = {
    isActive: false,
    selectedHeroName: undefined
}

const heroModalSlice = createSlice({
    name: 'heroModal',
    initialState,
    reducers: {
        dispatchHeroModalIsActive: (state, action: PayloadAction<boolean>) => {
            if (state.isActive !== action.payload) {
                state.isActive = action.payload
            }
        },
        dispatchSelectedHeroId: (state, action: PayloadAction<string>) => {
            if (state.selectedHeroName !== action.payload) {
                state.selectedHeroName = action.payload
            }
        },
    }
})

export const {
    dispatchHeroModalIsActive,
    dispatchSelectedHeroId,
} = heroModalSlice.actions

export default heroModalSlice.reducer